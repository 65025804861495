import type { AxiosRequestConfig } from 'axios';

import type {
  AxiosResponseWithError,
  MerchantRepresentativeEntity,
  MsApiResponse,
  PagedDataSource,
  SearchParams,
} from '../types/api';
import type { BuyerBusinessEntity } from '../types/buyers';
import type { BuyerUserMapping } from '../types/mappings';
import type { PortalEntity } from '../types/portals';
import BaseResource from './base';
import { MsApiEndPoints } from './endPoints';

export class BuyerResource extends BaseResource {
  /**
   * Retrieves a portal by portal slug.
   */
  list(options?: AxiosRequestConfig): Promise<AxiosResponseWithError<PortalEntity>> {
    return this.client.get<PortalEntity, MsApiResponse<PortalEntity>>(MsApiEndPoints.buyers.list(), options);
  }

  /**
   * Retrieves a portal by portal slug.
   */
  get(id: string, options?: AxiosRequestConfig): Promise<AxiosResponseWithError<PortalEntity>> {
    return this.client.get<PortalEntity, MsApiResponse<PortalEntity>>(MsApiEndPoints.buyers.get(id), options);
  }

  /**
   * Retrieves a buyer's account representative.
   */
  getAccountManager(options?: AxiosRequestConfig): Promise<AxiosResponseWithError<MerchantRepresentativeEntity>> {
    return this.client.get<MerchantRepresentativeEntity, MsApiResponse<MerchantRepresentativeEntity>>(
      `${MsApiEndPoints.buyers.getAccountManager()}`,
      options
    );
  }

  /**
   * Retrieve all buyers for a merchant
   */
  getAllBuyersByMerchantId(merchantId: string, searchParams?: SearchParams, options?: AxiosRequestConfig) {
    return this.client.get<BuyerBusinessEntity[], MsApiResponse<PagedDataSource<BuyerBusinessEntity>>>(
      MsApiEndPoints.buyers.getBuyersByMerchantId(merchantId),
      {
        params: searchParams,
        ...options,
      }
    );
  }

  /**
   * Retrieves a buyer's account representative.
   */
  getBuyerMerchantByBusinessId(
    businessId: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<BuyerUserMapping>> {
    return this.client.get<BuyerUserMapping, MsApiResponse<BuyerUserMapping>>(
      `${MsApiEndPoints.buyers.getBuyerMerchantByBusinessId(businessId)}`,
      options
    );
  }

  /**
   * Retrieves a Business by businessId
   */
  getBuyerBusinessByBusinessId(
    businessId: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponseWithError<BuyerBusinessEntity>> {
    return this.client.get<BuyerUserMapping, MsApiResponse<BuyerBusinessEntity>>(
      `${MsApiEndPoints.buyers.getBuyerBusinessByBusinessId(businessId)}`,
      options
    );
  }
}
